import { useEffect, useState } from "react";
import { Button, Col, Form, Nav, Row, Card } from "react-bootstrap";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import httpRequest, { httpGetRequest } from "./HttpRequestService";

const RetraitDeriv = (props) => {
    const [token, appId] = useOutletContext();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const session = query.get("session");

    if(!session){
        window.location.replace(`https://deriv.slifetracker.com`);
    }

    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [sending, setSending] = useState(false);

    const sendRequest = async () => {
        try {
            setSending(true);
            setError(false);
            setErrorMessage('');
            const response = await httpRequest(`retrait/${session}`, {
                session: session,
                code: code
            }, {
                token: token,
                appId: appId
            });
            if(response.data.error){
                setError(true);
                setErrorMessage(response.data.message);
            }else{
                window.location.replace(response.data.URI);
            }
        } catch (error) {
            if(error.response.data.message){
                setError(true);
                setErrorMessage(error.response.data.message);
            }
            setSending(false);
        }
    }

    const checkSession = async () => {
        try {
            setSending(true);
            const response = await httpGetRequest(`checkSession/${session}`, {
                token: token,
                appId: appId
            });
            setSending(false);
            if(response.data.valid){
                setEmail(response.data.email);
            }else{
                window.location.replace(`https://deriv.slifetracker.com`);
            }
        }catch(error) {
            if(error.response.data.message){
                setError(true);
                setErrorMessage(error.response.data.message);
            }
            setSending(false);
        }
    }

    useEffect(async () => {
        checkSession();
    }, []);

    return (
        <>
            <br/>
            <br/>
            <br/>
            <Row>
                <Col>
                    
                </Col>
                <Col sm={10}>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            {email != '' && <Form.Label style={{fontSize: '18px'}}><strong>Deriv vous a envoyé un mail contenant le token de vérification. <br/> (Email: {email})</strong></Form.Label>}
                            <Form.Control type="text" placeholder="Token" value={code} onChange={(event)=>setCode(event.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            {error != '' && <Form.Label style={{fontSize: '18px'}}><strong className="text-danger">{errorMessage}</strong></Form.Label>}
                        </Form.Group>
                        <Button disabled={(sending || code == '')} variant="primary" type="button" onClick={sendRequest}>
                            Continuer
                        </Button>
                    </Form>
                </Col>
                <Col>
                    
                </Col>
            </Row>
        </>
    )
}

export default RetraitDeriv;