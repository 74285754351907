import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { httpGetRequest } from "./HttpRequestService";



const Home = (props) => {
    const [token, appId] = useOutletContext();
    const [data, setData] = useState();

    const load = async (filtre = null) => {
        try {
            const response = await httpGetRequest(`derivBalance`, {
                token: token,
                appId: appId
            });
            if (response.data) {
                setData(response.data);
                console.log(response.data);
            }
        } catch (error) {
            //setLoading(false);
            //setErreur(error.response.data.message);
            console.log(error.response.data);
        }
    }

    useEffect(async () => {
        load();
    }, []);

    return (
        <>
            <Row></Row>
        </>
    )
}

export default Home;